const config = {
    app: {
        endpoint: process.env.REACT_APP_WEB_ENDPOINT,
        web_endpoint: process.env.REACT_APP_WEB_ENDPOINT,
        api_endpoint: process.env.REACT_APP_API_ENDPOINT,
        stage: process.env.REACT_APP_STAGE,
        license: process.env.REACT_APP_LICENSE,
        contract: process.env.REACT_APP_CONTRACT
    },
    aws:  {
        // 'aws_cognito_identity_pool_id': 'us-east-2:8e2cd637-a82e-4e4f-a030-84a1b4a1129c',
        'aws_project_region': process.env.REACT_APP_REGION,
        'aws_cognito_region': process.env.REACT_APP_REGION,
        'aws_user_pools_id': `${process.env.REACT_APP_USERPOOL_ID}`,
        'aws_user_pools_web_client_id': process.env.REACT_APP_CLIENT_ID,
        'oauth': {
            scope: [
                'phone', 
                'email', 
                'profile', 
                'openid',
                'aws.cognito.signin.user.admin', 
                'private-users/admin'
            ]
        },
        'aws_cognito_username_attributes': [],
        'aws_cognito_social_providers': [],
        'aws_cognito_signup_attributes': [],
        'aws_cognito_mfa_configuration': 'OFF',
        'aws_cognito_mfa_types': [],
        'aws_cognito_password_protection_settings': {
            'passwordPolicyMinLength': 8,
            'passwordPolicyCharacters': [
                'REQUIRES_LOWERCASE',
                'REQUIRES_UPPERCASE',
                'REQUIRES_NUMBERS',
                'REQUIRES_SYMBOLS'
            ]
        },
        'aws_cognito_verification_mechanisms': []
    }
};

export default config;