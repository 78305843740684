import React, { Component, Suspense } from 'react';
import { Amplify, I18n } from 'aws-amplify';
import NavigationView from '../../view/NavigationView';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { User } from 'firebase/auth';
import { Role } from '../../models/Role';
import Transfer from '../../models/Transfer';
import Item from '../../models/Item';
import { Container } from '@mui/material';
import ProgressBar from '../../higher-order-components/ProgressBar';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Config from '../../Config';
import Copyright from '../../view/Copyright';

Amplify.configure({
    ... Config.aws 
});

I18n.setLanguage('es');

const FindComponent = React.lazy(() => import('../FindComponent'));
const InventoryComponent = React.lazy(() => import('../InventoryComponent'));
const GenerateBarCodesComponent = React.lazy(() => import('../GenerateBarCodesComponent'));
const GenerateCustomBarCodesComponent = React.lazy(() => import('../GenerateCustomBarCodesComponent'));
const LocationComponent = React.lazy(() => import('../LocationComponent'));
const ScanComponent = React.lazy(() => import('../ScanComponent'));
const HomeComponent = React.lazy(() => import('../HomeComponent'));
const ItemComponent = React.lazy(() => import('../ItemComponent'));
const TransferComponent = React.lazy(() => import('../TransferComponent'));
const SingleItemLocationComponent = React.lazy(() => import('../SingleItemLocationComponent'));
const SingleLocationComponent = React.lazy(() => import('../SingleLocationComponent'));
const SingleMaterialComponent = React.lazy(() => import('../SingleMaterialComponent'));
const TemplateComponent = React.lazy(() => import('../TemplateComponent'));
const TemplateCopyComponent = React.lazy(() => import('../TemplateCopyComponent'));
const StorageComponent = React.lazy(() => import('../StorageComponent'));
const GroupComponent = React.lazy(() => import('../GroupComponent'));
const ClientComponent = React.lazy(() => import('../ClientComponent'));
const ClientGroupComponent = React.lazy(() => import('../ClientGroupComponent'));
const SingleGroupComponent = React.lazy(() => import('../SingleGroupComponent'));
const SingleClientComponent = React.lazy(() => import('../SingleClientComponent'));

interface ApplicationComponentState {
    user?: User | null,
    roles: Role[],
    transfers: Transfer[],
    items: Map<string, Item>,
    loading: boolean,
}
export default class ApplicationComponent extends Component<{}, ApplicationComponentState> {
    constructor(props: any) {
        super(props);
    }

    render = () => {
        if(Config.app.stage == 'DESKTOP') {
            return this.application({username: 'test'}, () => {});
        }

        return (<Authenticator hideSignUp={true}>
            {({user, signOut}) => this.application(user, signOut)}
        </Authenticator>);
    };

    application = (user : any, signOut : any) => 
    {{
        return (<Container maxWidth="xl">
            <NavigationView user={user}
                signOut={signOut} />
            <br />
            <Router>
                <Routes>
                    <Route path="/"
                        element={<Suspense fallback={<ProgressBar />}>
                            <HomeComponent username={user?.username} />
                        </Suspense>} />
                    <Route path="/search"
                        element={<Suspense fallback={<ProgressBar />}>
                            <FindComponent />
                        </Suspense>} />
                    <Route path="/inventory"
                        element={<Suspense fallback={<ProgressBar />}>
                            <InventoryComponent />
                        </Suspense>} />
                    <Route path="/storage"
                        element={<Suspense fallback={<ProgressBar />}>
                            <StorageComponent />
                        </Suspense>} />
                    <Route path="/materials/create"
                        element={<Suspense fallback={<ProgressBar />}>
                            <SingleMaterialComponent enabled={true}
                                template={false} />
                        </Suspense>} />
                    <Route path="/material/:itemId"
                        element={<Suspense fallback={<ProgressBar />}>
                            <SingleMaterialComponent enabled={false}
                                template={false} />
                        </Suspense>} />
                    <Route path="/templates/create"
                        element={<Suspense fallback={<ProgressBar />}>
                            <SingleMaterialComponent enabled={true}
                                template={true} />
                        </Suspense>} />
                    <Route path="/template/:itemId"
                        element={<Suspense fallback={<ProgressBar />}>
                            <SingleMaterialComponent enabled={false}
                                template={true} />
                        </Suspense>} />
                    <Route path="/copy/:itemId"
                        element={<Suspense fallback={<ProgressBar />}>
                            <TemplateCopyComponent />
                        </Suspense>} />
                    <Route path="/locations"
                        element={<Suspense fallback={<ProgressBar />}>
                            <LocationComponent />
                        </Suspense>} />
                    <Route path="/materials"
                        element={<Suspense fallback={<ProgressBar />}>
                            <ItemComponent />
                        </Suspense>} />
                    <Route path="/templates"
                        element={<Suspense fallback={<ProgressBar />}>
                            <TemplateComponent />
                        </Suspense>} />
                    <Route path="/groups"
                        element={<Suspense fallback={<ProgressBar />}>
                            <GroupComponent />
                        </Suspense>} />
                    <Route path="/group/add"
                        element={<Suspense fallback={<ProgressBar />}>
                            <SingleGroupComponent />
                        </Suspense>} />
                    <Route path="/clients"
                        element={<Suspense fallback={<ProgressBar />}>
                            <ClientComponent />
                        </Suspense>} />
                    <Route path="/client/:clientId"
                        element={<Suspense fallback={<ProgressBar />}>
                            <ClientGroupComponent />
                        </Suspense>} />
                    <Route path="/associate"
                        element={<Suspense fallback={<ProgressBar />}>
                            <SingleClientComponent />
                        </Suspense>} />
                    <Route path="/audit"
                        element={<Suspense fallback={<ProgressBar />}>
                            <TransferComponent />
                        </Suspense>} />
                    <Route path="/locations/create"
                        element={<Suspense fallback={<ProgressBar />}>
                            <SingleLocationComponent enabled={true} />
                        </Suspense>} />
                    <Route path="/location/:locationId"
                        element={<Suspense fallback={<ProgressBar />}>
                            <SingleLocationComponent enabled={false} />
                        </Suspense>} />
                    <Route path="/bar-codes/create"
                        element={<Suspense fallback={<ProgressBar />}>
                            <GenerateBarCodesComponent />
                        </Suspense>} />
                    <Route path="/bar-codes/custom"
                        element={<Suspense fallback={<ProgressBar />}>
                            <GenerateCustomBarCodesComponent />
                        </Suspense>} />
                    <Route path="/item-location/:itemLocationId"
                        element={<Suspense fallback={<ProgressBar />}>
                            <SingleItemLocationComponent />
                        </Suspense>} />
                    <Route path="/bar-code/:itemLocationId"
                        element={<Suspense fallback={<ProgressBar />}>
                            <ScanComponent />
                        </Suspense>} />
                </Routes>
            </Router>
            <br />
            <Copyright />
        </Container>);}
    };
}