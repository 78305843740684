import { orange, red, yellow } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import ApplicationComponent from './components/ApplicationComponent';

const theme = createTheme({
    palette: {
        primary: {
            main: orange[400]
        },
        secondary: {
            main: yellow[500]
        },
        error: {
            main: red[600]
        }
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <ApplicationComponent />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
