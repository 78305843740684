import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@material-ui/icons/Menu';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import LogoutIcon from '@mui/icons-material/Logout';
import InventoryIcon from '@mui/icons-material/Inventory';
import SearchIcon from '@mui/icons-material/Search';
import StorageIcon from '@mui/icons-material/Storage';
import GroupsIcon from '@mui/icons-material/Groups';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HardwareIcon from '@mui/icons-material/Hardware';
import HandshakeIcon from '@mui/icons-material/Handshake';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { AppBar, Box, Collapse, Container, Drawer, IconButton, InputBase, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export interface NavigationViewProps {
    user: any
    signOut: any
}
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const stringToColor = (string: string) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
};
const stringAvatar = (name: string) => {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
    };
};

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '48ch',
            '&:focus': {
                width: '48ch',
            },
        },
    },
}));

const NavigationView: React.FC<NavigationViewProps> = ({user, signOut}) => {
    const [barCodesButton, setBarCodesButton] = React.useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setIsDrawerOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                    Inventory Management
                    </Typography>
                    {/*<Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search Materials…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>*/}
                    <Box sx={{ flexGrow: 1} } />
                    {user && <Link
                        style={{ 
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit', }}
                        href={'/profile'}
                    >
                        {user.username!}
                    </Link>}
                    <Drawer open={isDrawerOpen}
                        onClose={() => setIsDrawerOpen(false)}>
                        <List sx={{ width: '100%', minWidth: 200, maxWidth: 400, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader">
                            <ListItem button
                                component={'a'}
                                href="/">
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItem>
                            <ListItem button
                                component={'a'}
                                href="/search">
                                <ListItemIcon>
                                    <SearchIcon />
                                </ListItemIcon>
                                <ListItemText primary="Search" />
                            </ListItem>
                            <ListItem button
                                component={'a'}
                                href="/locations">
                                <ListItemIcon>
                                    <WarehouseIcon />
                                </ListItemIcon>
                                <ListItemText primary="Locations" />
                            </ListItem>
                            <ListItem button
                                component={'a'}
                                href="/materials">
                                <ListItemIcon>
                                    <HardwareIcon />
                                </ListItemIcon>
                                <ListItemText primary="Materials" />
                            </ListItem>
                            <ListItem button
                                component={'a'}
                                href="/templates">
                                <ListItemIcon>
                                    <ContentCopyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Templates" />
                            </ListItem>
                            <ListItem button
                                component={'a'}
                                href="/storage">
                                <ListItemIcon>
                                    <StorageIcon />
                                </ListItemIcon>
                                <ListItemText primary="Storage" />
                            </ListItem>
                            <ListItem button
                                component={'a'}
                                href="/inventory">
                                <ListItemIcon>
                                    <InventoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Inventory" />
                            </ListItem>
                            <ListItem button
                                component={'a'}
                                href="/groups">
                                <ListItemIcon>
                                    <GroupsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Groups" />
                            </ListItem>
                            <ListItem button
                                component={'a'}
                                href="/clients">
                                <ListItemIcon>
                                    <HandshakeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Clients" />
                            </ListItem>
                            <ListItem button
                                component={'a'}
                                href="/audit">
                                <ListItemIcon>
                                    <FactCheckIcon />
                                </ListItemIcon>
                                <ListItemText primary="Audit" />
                            </ListItem>
                            <ListItemButton onClick={() => setBarCodesButton(!barCodesButton)}
                                sx={{ display: 'flex' }}>
                                <ListItemIcon>
                                    <QrCode2Icon />
                                </ListItemIcon>
                                <ListItemText primary="Bar Codes" />
                                {barCodesButton ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={barCodesButton}
                                timeout="auto"
                                unmountOnExit>
                                <List component="div"
                                    disablePadding>
                                    <ListItemButton component={'a'}
                                        href="/bar-codes/create"
                                        sx={{ pl: 4, display: 'flex' }}>
                                        <ListItemText primary="Create Inventory Bar Codes (QR)" />
                                    </ListItemButton>
                                    <ListItemButton component={'a'}
                                        href="/bar-codes/custom"
                                        sx={{ pl: 4, display: 'flex' }}>
                                        <ListItemText primary="Create Custom Bar Codes (QR)" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItem button
                                component={'a'}
                                onClick={signOut}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sign Out" />
                            </ListItem>
                        </List>
                    </Drawer>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavigationView;
