import { Link, Typography } from '@mui/material';
import Config from '../../Config';

export default function Copyright(props: any) {
    return (
        <Typography variant="body2"
            color="text.secondary"
            align="center"
            {...props}>
            {'Licensed to '}
            <Link color="inherit"
                href={Config.app.license}>
            INS Construction Corp
            </Link>{', '}
            {'Operated & Maintained by '}
            <Link color="inherit"
                href={Config.app.contract}>
            Stacked Sites LLC
            </Link>
            <br />
            {'Copyright © '}
            <Link color="inherit"
                href={Config.app.contract}>
            Stacked Sites LLC
            </Link>{', '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}